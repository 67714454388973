@import 'variables';
@import 'mixins';
@import '../common/mixins.scss';
@import '../common/refactor/mixins';

:root {
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
}

body {
	font-size: 16px;
	color: black;
}

.out {
	margin-bottom: 0;
}

//.header__i
// display: none

/* ==== Header ==== */
.header {
	background: $white;
	border-bottom: 1px solid #dddddd;

	.logo {
		.img {
			background: $logo;
			@include fastTransition(opacity, 0.15s);

			&:hover {
				opacity: 0.6;
			}
		}
	}
}

.userNav {
	&__i {
		a {
			color: $black;
			@include fastTransition(color, 0.1s);

			&:hover {
				color: $redBrandColor;
			}
		}
	}
}

/* ==== END Header ==== */

/* ==== Search step ==== */

.searchSteps {
	ol {
		li {
			&.active {
				color: $white;
				background: $blueBrandColor;

				&:after {
					border-color: $white;
				}
			}

			a {
				&:hover {
					background: $redBrandColor;
					color: $white;
				}
			}

			&.prev {
				&:hover {
					&:after {
						border-color: $white;
						color: $white;
					}
				}
			}
		}
	}
}

.orderSearchForm {
	.btnTransfer {
		&:hover {
			background: $redBrandColor;
			border-color: $redBrandColor;
		}
	}

	.valueControl {
		&.active {
			a {
				&:hover {
					background: $redBrandColor;
				}

				&.btnMinus {
					&:hover {
						background: $redBrandColor;

						i {
							color: $white;
						}
					}
				}
			}
		}

		a {
			i {
				color: $blueBrandColor;
			}

			&:hover {
				background: $redBrandColor;
			}
		}
	}
}

.btn {
	&.btn_search {
		background: $blueBrandColor;
		color: $white;

		&:hover {
			background: $redBrandColor;
		}
	}
}

/* ==== END Search step ==== */

/* ==== Choose flight step ==== */

.chooseDate {
	.tableWrap {
		table {
			td {
				&.active {
					background: $blueBrandColor;

					a {
						color: $white;

						&:hover {
							background: $lightBlueBrandColor;
						}
					}
				}
			}
		}
	}
}

/* ==== END Choose flight step ==== */

.flightTable tr td.active,
.flightTable .contentRow td.active a {
	background: $blueBrandColor;
	color: white;
}

.searchResultsControl .btn_next {
	@include blueButton;
}

/* ==== #38002 ==== */

/* calendar styles */

@include calendarHoverStyles {
	background: $lightBlueBrandColor !important;
}

@include calendarSelectedStyles {
	background: lighten($redBrandColor, 20%) !important;
}

@include rangeCalendarStyles {
	background: lighten($redBrandColor, 10%) !important;
	color: white;
}

@include rangeStartEndStyles {
	background: lighten($redBrandColor, 20%) !important;
	color: white;
}

@include enabledCalendarStyles {
	background-color: lighten($flexibleBrandColor, 10%);
	color: white;
}

/* end calendar styles */

/* flight detail */
.popup_detail .route_item .route_container .route_table .dep,
.popup_detail .route_item .route_container .route_table .arr {
	vertical-align: middle;
}

/* button in login popup */
/* and save button in user account */
.popup__i__footer__buttons__button,
.btn.btn_save {
	background: $blueBrandColor;
	color: white;

	&:hover {
		background: $redBrandColor;
	}
}

/* add fare families */
.flightTable > table > tbody > tr > td {
	border-right: 1px solid #e6e6e6;
	border-bottom: 1px solid #e6e6e6;
	border-top: 1px solid #e6e6e6;
}

/* ==== END #38002 ==== */

/* ==== #38002#note-17 ==== */

.chooseDate .tableWrap table td.active a strong {
	color: white;
}

@include changeHeight;

.selectDate .item.active {
	background: $blueBrandColor;
	color: white;

	a {
		color: inherit;

		> span:last-child {
			color: white;
		}
	}
}

.chooseFlight__table__item__content__link {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
}

.chooseFlight__table__item__content.active .chooseFlight__table__item__content__link {
	background: $blueBrandColor;
	color: white;

	&:hover {
		background: $lightBlueBrandColor;
	}

	@include adaptivity(tablet) {
		&:hover {
			background: $blueBrandColor;
		}
	}
}

.header.header_small .logo {
	//width: auto
	width: 100%;
	max-width: 100%;

	.img {
		background-repeat: no-repeat;
	}

	@include adaptivity(tablet);
}

//width: 210px

.mainNavBtn span {
	background: $blueBrandColor;
}

.compareTabsWrap .tabsControl li a {
	background: #6183c7;
	color: white;
}

.compareTabsWrap .name__i {
	background: #6183c7;
	color: white;
}

.flightTable .chooseFlight__table__item__columnTitle {
	background: #6183c7;
	text-align: center;

	.name a {
		display: inline-block;
		font-size: 13px;
		line-height: 14px;
		font-weight: 700;
		color: white;
		border-bottom: 1px dashed white;
	}
}

.ui-select-header .selectize-input .ui-select-match,
.ui-select-header .ui-select-placeholder.text-muted {
	color: #3c3838;
	@include adaptivity(tablet) {
		color: white;
	}
}

.ui-select-header .selectize-input:after {
	border-color: #b0b0b0 transparent transparent transparent !important;
	@include adaptivity(tablet) {
		border-color: white transparent transparent transparent !important;
	}
}

/* ==== END #38002#note-17 ==== */

/* ==== #38002#note-41 ==== */

.flightTable .contentRow .price-cell__link {
	box-sizing: border-box;
	padding: 39px 10px;
}

.flightTable .contentRow .price-cell {
	&__link {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__text {
		width: auto;
	}
}

/* ==== END #38002#note-41 ==== */

/* ==== #38002#note-48 ==== */

.flightTable .contentRow td.active {
	.price-cell__text {
		display: inline-block;
		position: relative;

		&:before {
			font-family: 'OpenSkyIcons' !important;
			content: $checkIcon;
			display: block;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			font-size: 14px;
			left: -23px;
		}
	}
}

.btn_receipt_cont a.show_receipt,
.btn_receipt_cont a.send_receipt {
	background-color: $blueBrandColor;
	color: white;

	&:hover {
		background-color: $redBrandColor;
	}
}

.btn_receipt_cont a.send_receipt {
	background-image: $receiptIcon;
}

.invoiceDrops .title {
	background: $blueBrandColor;
}

.invoiceDrops .item .circle i {
	color: $blueBrandColor;
}

.popup .title__i {
	background: $blueBrandColor;
}

.invoice .btn-row .btn {
	@include blueButton;
}

/* ==== END #38002#note-48 ==== */

/* ==== remove border at items in flightTable ==== */

.flightTable .contentRow td:not(.firstCol) {
	//border: none
	border-top: none;
}

.flightTable {
	.options {
		i {
			width: 20px;
			height: 20px;
		}

		.icon {
			&_carryon_yes,
			&_carryon {
				background-image: $carryonYesIcon;

				&:before {
					display: none;
				}
			}

			&_ticket-refund {
				&_no {
					background-image: $ticketRefundNoIcon;
				}

				&_yes {
					background: $ticketRefundYesIcon;
				}
			}

			&_luggage {
				background: $luggageYesIcon;

				&_no {
					background: $luggageNoIcon;
				}

				&_yes {
					background: $luggageYesIcon;
				}

				&:before {
					display: none;
				}
			}

			&_seat {
				&_no {
					background: $seatNoIcon;
				}

				&_yes {
					background: $seatYesIcon;
				}
			}

			&_ticket-change {
				&_no {
					background: $ticketChangeNoIcon;
				}

				&_yes {
					background: $ticketChangeYesIcon;
				}

				&:before {
					display: none;
				}
			}

			&_ticket-refund {
				background-image: $ticketChangeYesIcon;
				margin-top: 0;

				&_no {
					background: $ticketRefundNoIcon;
				}

				&_yes {
					background-image: $ticketRefundYesIcon;
				}

				&:before {
					display: none;
				}
			}

			&_child-discount {
				&_yes {
					background: $childDiscountYesIcon no-repeat;
					background-size: contain;
					width: 17px;
					position: relative;
					top: 1px;
				}

				&_no {
					background: $childDiscountNoIcon no-repeat;
					background-size: contain;
					width: 19px;
					position: relative;
					top: 2px;
				}
			}
		}
	}
}

.flightTable .options i.icon_ticket-refund {
	margin-top: 0;
}

.flightTable {
	.headrow {
		td:not(:first-child) {
			border-bottom: 1px solid white;

			.name {
				color: white;
				font-weight: bold;

				a {
					text-decoration: dotted;
					color: inherit;
					border-bottom: 1px dashed white;

					&:hover {
						border-bottom: none;
					}
				}
			}
		}
	}

	.promo {
		background: $promoBrandColor;
	}

	.light {
		background: $lightBrandColor;
	}

	.flexible,
	.special {
		background: $flexibleBrandColor;
	}

	.standart {
		background: $premiumBrandColor;
	}

	.premium {
		background: $premiumBrandColor;
	}
}

.compareTableWrap {
	.promo,
	.light,
	.flexible,
	.standart,
	.premium {
		color: white;
	}

	.promo {
		background: $promoBrandColor;
	}

	.light {
		background: $lightBrandColor;
	}

	.flexible,
	.special {
		background: $flexibleBrandColor;
		color: white;
	}

	.standart {
		background: $premiumBrandColor;
	}

	.premium {
		background: $premiumBrandColor;
	}
}

/* ==== END remove border at items in flightTable ==== */

/* compare table icons */
.compareTableWrap,
.compareTabsWrap {
	.col1 {
		i {
			background-size: contain !important;
			display: inline-block;
			width: 20px;
			height: 30px;

			&:not(.icon_transfer):before {
				display: none;
			}
		}

		.icon {
			&_ticket-refund-after-departure {
				background: $ticketRefundAfterDepartureIcon no-repeat center center;
			}

			&_seat {
				background: $seatGrayIcon no-repeat center center;
				width: 13px;
				margin-left: 3px;

				&_comfortable {
					background: $seatGrayIcon no-repeat center center;
					width: 13px;
					margin-left: 3px;
				}
			}

			&_ticket-refund {
				background: $ticketRefundGrayIcon no-repeat center center;
			}

			&_luggage {
				background: $luggageGrayIcon no-repeat center center;
				width: 15px;
				margin-left: 4px;
			}

			&_carryon {
				background: $carryonGrayIcon no-repeat center center;
				width: 15px;
				margin-left: 4px;
			}

			&_ticket-change {
				background: $ticketChangeGrayIcon no-repeat center center;
			}

			&_captcha-reload {
				background: $ticketChangeAfterDeparture no-repeat center center;
			}

			&_child-discount,
			&_users {
				background: $childDiscountGrayIcon no-repeat center center;
				width: 15px;
				margin-left: 2px;
			}

			&_transfer {
				&:before {
					content: '\e930';
					font-size: 16px;
					position: relative;
					top: 6px;
					left: 1px;
				}
			}
		}
	}
}

.flightTable {
	.options {
		li {
			padding-right: 5px !important;
		}
	}
}

/* ==== 38002#note-98 ==== */

.tariff__content .tariff__content__item {
	.icon_check {
		color: #56cb00;
		//top: 0
		transform: translate(0, -140%);
		@include adaptivity(tablet) {
			transform: translate(0, -90%);
		}
	}

	&.prop-luggage,
	&.prop-carryon {
		.icon_check {
			top: 50%;
			transform: translate(0, -140%);
			@include adaptivity(phone) {
				transform: translate(0, -70%);
			}
		}
	}
}

.tariff__content .tariff__content__item {
	.currency_sign {
		font-weight: normal;
		font-size: 15px;
		color: #666666;
		position: absolute !important;
		left: -4px;
		top: 0;
		transform: translate(0, 20%) !important;
	}

	&.prop-luggage,
	&.prop-carryon {
		.currency_sign {
			top: 50%;
			transform: translate(0, -64%);
		}
	}
}

.tariff__content .tariff__content__item {
	.icon_none {
		left: -10px;
		background: $crossIcon center no-repeat;
		background-size: contain;
		width: 18px;
		height: 18px;
		top: 0;
		transform: translate(0, 50%) !important;

		&:before {
			display: none;
		}
	}

	&.prop-luggage,
	&.prop-carryon {
		.icon_none {
			top: 50%;
			transform: translate(0, -64%) !important;
			@include adaptivity(phone) {
				transform: translate(0, -24%) !important;
			}
		}
	}
}

.chooseResult__i .tariff__content__item,
.searchResultsPlain__i .tariff__content__item {
	position: relative;
	padding-left: 15px;
	break-inside: avoid;
	display: none;
	flex-direction: column;
	flex: 50% 0 0;
	width: auto;
	justify-content: center;
	@include adaptivity(phone) {
		flex: 100%;
	}

	i {
		position: absolute;
		left: -8px;
		top: 50%;
		transform: translate(0, -50%);
	}
}

.tariff__include {
	display: flex;
	justify-content: space-between;
}

.chooseResult {
	&__i {
		.col:nth-of-type(2) {
		}

		.total {
			.right {
				display: none;
			}
		}
	}
}

.tariff__content {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	height: 100px;
	@include adaptivity(phone) {
		flex-direction: row;
		height: auto;
	}

	&__item {
		display: flex;
	}

	> span {
		display: none;
	}

	.prop {
		&-carryon {
			display: block;
		}

		&-luggage {
			display: block;
		}

		&-ticket-refund {
			display: block;
		}

		&-ticket-change {
			display: block;
		}

		&-seat {
			display: block;
		}
	}
}

.tariff {
	&__prop {
		&-shortDesc {
			background-color: rgb(232, 232, 232);
			display: inline-block;
		}
	}
}

@mixin addNumberAfter($num) {
	.col1 span::after {
		content: #{$num};
		vertical-align: 60%;
		color: red;
		font-size: 10px;
	}
}

.compareTableWrap table {
	tr {
		&:nth-of-type(4) {
			@include addNumberAfter(1);
		}

		&:nth-of-type(5) {
			@include addNumberAfter(2);
		}
	}

	td {
		padding: 8px 0;
	}

	tr:not(:first-of-type) {
		td:not(:first-of-type) {
			font-weight: 400;
		}
	}
}

.coment-t {
	font-size: 11px;
	padding-top: 20px;

	p {
		font-size: 10px;
	}

	li {
		list-style-type: none;
		padding-bottom: 5px;
	}

	.red_note {
		color: red;
	}

	a {
		color: #0b6cb1;
		transition: border, 450ms, ease;
		display: inline-block;
		padding: 0;
		border-bottom: 1px dashed #0b6cb1;
		white-space: nowrap;

		&:hover {
			color: #0b6cb1;
			border-bottom: 1px dashed white;
		}
	}
}

/* ==== END 38002#note-98 ==== */

@mixin colorizeMobileCompareTable($brandName, $color) {
	#{$brandName} {
		.name__i {
			background: $color;
		}
	}
	.tabsControl {
		#{$brandName} {
			a {
				background-color: $color;
			}
		}
	}
}

.compareTabsWrap {
	@include colorizeMobileCompareTable('.flexible, .special', $flexibleBrandColor);
	@include colorizeMobileCompareTable('.light', $lightBrandColor);
	@include colorizeMobileCompareTable('.premium', $premiumBrandColor);
	@include colorizeMobileCompareTable('.standart', $premiumBrandColor);
}

@mixin addNumberInMobileCompareTable($num) {
	td:nth-of-type(1) {
		span {
			&:after {
				display: inline;
				content: #{$num};
				vertical-align: 60%;
				color: red;
				font-size: 10px;
			}
		}
	}
}

.compareTabsWrap.mobileShow {
	.tabsBody {
		tr:nth-of-type(3) {
			@include addNumberInMobileCompareTable(1);
		}

		tr:nth-of-type(4) {
			@include addNumberInMobileCompareTable(2);
		}

		tr {
			td:nth-of-type(2) {
				.currency_sign {
					padding-left: 0;
				}
			}

			td:nth-of-type(1) {
				i {
					background-size: contain !important;
					display: inline-block;
					width: 20px;
					height: 30px;

					&:not(.icon_transfer):before {
						display: none;
					}
				}

				.icon {
					&_ticket-refund-after-departure {
						background: $ticketRefundAfterDepartureIcon no-repeat center center;
					}

					&_seat {
						background: $seatGrayIcon no-repeat center center;
						width: 13px;
						margin-left: 3px;

						&_comfortable {
							background: $seatGrayIcon no-repeat center center;
							width: 13px;
							margin-left: 3px;
						}
					}

					&_ticket-refund {
						background: $ticketRefundGrayIcon no-repeat center center;
					}

					&_luggage {
						background: $luggageGrayIcon no-repeat center center;
						width: 15px;
						margin-left: 4px;
					}

					&_carryon {
						background: $carryonGrayIcon no-repeat center center;
						width: 15px;
						margin-left: 4px;
					}

					&_ticket-change {
						background: $ticketChangeGrayIcon no-repeat center center;
					}

					&_captcha-reload {
						background: $ticketChangeAfterDeparture no-repeat center center;
					}

					&_child-discount,
					&_users {
						background: $childDiscountGrayIcon no-repeat center center;
						width: 15px;
						margin-left: 2px;
					}

					&_transfer {
						&:before {
							content: '\e930';
							font-size: 16px;
							position: relative;
							top: 6px;
							left: 1px;
						}
					}
				}
			}
		}
	}
}

.popup_detail {
	.tariff {
		&__content {
			@media screen and (max-width: 900px) {
				flex-direction: row;
			}
			@include adaptivity(tablet) {
				flex-direction: row;
			}

			&__item {
				width: auto;
				position: relative;
				padding-left: 10px;
				flex: 50% 0 0;
				@media screen and (max-width: 900px) {
					flex: 50%;
				}
				@include adaptivity(phone) {
					flex: 100%;
				}

				&.prop-child-discount,
				&.prop-transfer {
					display: none;
				}

				.icon_check {
					position: absolute;
					left: -10px;
					transform: translate(0, 20%);
				}

				&.prop-luggage,
				&.prop-carryon {
					.icon_check {
						position: absolute;
						left: -7px;
						transform: translate(0, -140%);
						@include adaptivity(phone) {
							transform: translate(0, -46%);
						}
					}
				}
			}
		}

		.icon_none {
			display: inline-block;
			position: absolute;
		}
	}
}

.infoTooltip {
	background: $blueBrandColor;
	white-space: pre;
	bottom: 100%;
	padding: 10px 20px;
	@include adaptivity(phone) {
		white-space: pre-line;
		min-width: 320px;
		top: -180%;
	}

	&:after {
		border-color: $blueBrandColor transparent transparent transparent;
	}
}

.c-navbar-site {
	display: flex;
}

.navbar-toggle {
	display: none;
}

.nav {
	$triangleSize: 15px;

	> li {
		display: inline-block;
		position: relative;
		padding: 15px;
		font-size: 13px;
		cursor: pointer;

		> a {
			@include fastTransition((color, opacity), 0.15s);
		}

		&:after {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 $triangleSize $triangleSize $triangleSize;
			border-color: transparent transparent #313131 transparent;
			display: none;
			top: 73%;
			left: 30px;
		}

		&:hover {
			> a {
				color: #e3000f;
				opacity: 0.5;
			}

			> div {
				display: block;
				min-width: 280px;
				border-radius: 5px;

				.row {
					display: flex;
				}
			}

			&:after {
				display: block;
			}
		}

		> div {
			background: $tooltipBackground;
			position: absolute;
			z-index: 3;
			top: 100%;
			display: none;
			padding: 15px;

			&.wide {
				min-width: 770px;

				h3 {
					font-size: 14px;
					color: white;
					text-transform: none;
					font-weight: 500;
					margin: 20px 0 10px;
				}
			}

			li {
				display: block;
				padding: 7px 10px;
			}

			a {
				color: #b7b7b7;
				@include fastTransition((text-decoration, color), 0.15s);

				&:hover {
					text-decoration: underline;
					color: white;
				}
			}
		}
	}
}

footer {
	background: $footerBackground;
	min-height: 221px;
	padding: 40px 0;
	color: $white;

	.c-menu-title {
		margin: 10px 0 30px;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 500;
	}

	.c-info-phone {
		font-size: 24px;
		margin-bottom: 10px;
	}

	.text-center {
		text-align: center;
		margin: 0;
		font-size: 14px;
	}

	p {
		margin: 15px 0;
	}

	a {
		color: white;
		font-size: 14px;
		@include fastTransition(opacity, 0.15s);

		&:hover,
		&:focus {
			opacity: 0.6;
			text-decoration: underline;
		}
	}

	.container {
		max-width: 962px;
		margin: 0 auto;
	}

	.row {
		display: flex;
		justify-content: space-between;
		@include adaptivity(tablet) {
			flex-direction: column;
			padding: 0 10px;
		}

		> div {
			&:nth-of-type(1) {
				flex: 2;
			}

			&:nth-of-type(2) {
				flex: 1;
				text-transform: uppercase;
				@include adaptivity(tablet) {
					margin-top: 30px;
				}

				> h5 {
					font-weight: 500;
					font-size: 14px;
					margin: 10px 0;
				}
			}
		}
	}
}

.tariff__content .tariff__content__item .icon_none {
	transform: translate(0, 12%) !important;
}

.tariff__content .prop-luggage.unavailable {
	position: relative;
	top: -9px;

	span {
		color: transparent;
		font-size: 0;

		&:before {
			content: 'Багаж не входит в тариф';
			color: #b3b3b3;
			position: relative;
			top: 9px;
			font-size: 13px;
		}
	}
}

html[lang='en'] {
	.tariff__content .prop-luggage.unavailable span:before {
		content: 'Checked baggage Not included';
	}
}

/* ==== upt ==== */

.remark_tabs .active {
	background: $blueBrandColor;
}

.remark_text {
	border-top-color: $blueBrandColor;
}

/* ==== END upt ==== */

/* ==== private-order ==== */

.customCheckbox input:checked + span:after {
	color: $blueBrandColor;
}

/* ==== END private-order ==== */

.checkoutServices__i .list > .item .top {
	background: $blueBrandColor;
}

.checkoutServices__i .list > .item {
	background: white;

	&.active .top .iconBox {
		background: white;
	}
}

.checkoutServices__i .list > .item {
	color: $blueBrandColor;

	&.active .top .iconBox i {
		color: $redBrandColor;
	}
}

.checkoutServices__i .list > .item {
	color: white;

	&.active .top .name h2 {
		color: white;
	}
}

.checkoutServices__i .list > .item {
	color: white;

	.active .top .price h3 {
		color: white;
	}
}

.checkoutServices__i .iconBox {
	background: white;

	i {
		color: $blueBrandColor;
	}
}

.checkoutServices__i .list > .item.active .top .price h3 {
	color: white;
}

.checkoutServices__i .list > .item {
	&:not(.active) {
		.top .btn {
			@include blueButton;
			background: $redBrandColor;
			border-color: transparent;
		}
	}
}

/* ==== mob extra services ==== */

.checkoutServices__i .list > .item .item__i .mobileShow .top table td:first-child,
.checkoutServices__i .list > .item .item__i.mobileShow .top table td:first-child {
	background: $blueBrandColor;
}

.checkoutServices__i .row:first-child {
	background: lighten($blueBrandColor, 10%);
}

.checkoutServices__i .row {
	background: lighten($blueBrandColor, 15%);
}

.selectedInfo {
	background: $blueBrandColor;
}

/* ==== END mob extra services ==== */

/* ==== payment cards ==== */

.payMethods .systems {
	ul {
		position: relative;
		@include adaptivity(phone) {
			display: flex;
			flex-wrap: wrap;
		}

		&:before,
		&:after {
			content: '';
			display: inline-block;
			min-width: 54px;
			min-height: 27px;
			visibility: visible;
		}

		&:before {
			background: $mcElectronIcon no-repeat;
			background-size: contain;
		}

		&:after {
			background: $jcbCardIcon no-repeat;
			background-size: contain;
		}

		li {
			&.ae,
			&.unionpay {
				display: none;
			}
		}
	}
}

.flightTable .contentRow .price-cell__i {
	height: auto;
}

/* ==== END payment cards ==== */

/* ==== floating labels ==== */

.inp-animate-outer {
	margin-top: 16px;
	position: relative;

	input {
		font-size: 0.9em;
		@include placeholder {
			color: transparent;
		}
	}
}

.inp-animate-label {
	position: absolute;
	top: 0;
}

.passengersInfo input[type='text'],
.passengersInfo input[type='password'],
.passengersInfo input[type='tel'],
.passengersInfo input[type='email'] {
	height: 40px;
}

.inp-animate-placeholder:focus ~ .inp-animate-label > span,
.inp-animate-placeholder.ng-not-empty ~ .inp-animate-label > span,
.ui-select-passengers.ng-valid ~ .inp-animate-label > span {
	transition: transform 0.3s;
	transform: translate3d(-10px, -30px, 0);
	border: none;
	background: none;
}

.inp-animate-label {
	top: 0;
	position: absolute;
	width: 100%;
	text-align: left;
	pointer-events: none;
	display: block;
	user-select: none;

	span {
		padding: 11px 10px;
		display: block;
		border: 1px solid #e3e3e3;
		background: white;
		border-radius: 3px;
		font-size: 0.9em;
		color: #666666;
	}
}

/* ==== END floating labels ==== */

/* ==== awesome-search-form ==== */

$searchFormSettings: (
	mainBgColor: white,
	inputs: (
		color: $black,
		placeholderColor: $black,
		selectedCityColor: $black,
		selectedCountryColor: $black,
		selectedCodeColor: $black,
		dropdown: (
			dropdownPortColor: $blueBrandColor,
			dropdownCityColor: $blueBrandColor,
			dropdownCountryColor: $blueBrandColor,
			dropdownCodeColor: $blueBrandColor
		)
	),
	svg: (
		color: $blueBrandColor
	),
	swapIcon: (
		svgColor: $blueBrandColor,
		hoverSvgColor: white,
		borderColor: #ccc,
		bgColor: $blueBrandColor,
		hoverBgColor: $blueBrandColor
	),
	calendar: (
		titleColor: $blueBrandColor,
		monthChangeButtons: (
			backgroundColor: white,
			color: $gray,
			hoverColor: white,
			backgroundHoverColor: $blueBrandColor
		),
		dates: (
			today: (
				color: white,
				background: lighten($blueBrandColor, 20%)
			),
			selected: (
				color: white,
				background: $redBrandColor
			),
			normal: (
				color: black,
				background: transparent
			),
			disabled: (
				background: transparent,
				color: #d6d6d6
			),
			hover: (
				color: white,
				background: $blueBrandColor
			)
		),
		bottomButton: (
			color: $blueBrandColor,
			border-color: $blueBrandColor,
			hover: (
				color: white,
				borderColor: $redBrandColor,
				backgroundColor: $redBrandColor
			)
		)
	),
	searchButton: (
		color: white,
		backgroundColor: $blueBrandColor,
		hover: (
			color: white,
			backgroundColor: $redBrandColor
		)
	),
	passengers: (
		color: $blueBrandColor,
		ageColor: $gray
	)
);

@include awesomeSearchForm($searchFormSettings);
.awesome-date-picker__value-date,
.awesome-date-picker__value-day,
.awesome-city-picker-field__selected-city,
.awesome-city-picker-field__selected-port,
.awesome-city-drop__item_hover .awesome-city-drop__port,
.awesome-city-drop__item_hover .awesome-city-drop__city,
.awesome-city-picker-field__selected-country,
.awesome-city-picker-field__placeholder,
.awesome-city-drop__placeholder-text,
.awesome-date-picker__placeholder,
.awesome-date-picker__locker,
.passenger-calc-field__count,
.bonus-control__intro,
.bonus-control__field .textInp,
.passenger-calc-line__title {
	font-weight: 400;
}

/* ==== END awesome-search-form ==== */

/* ==== insurance rules ==== */

/* ==== END insurance rules ==== */

.insurance__rules-popup-text {
	text-align: center;
	font-style: italic;
	white-space: pre-line;
	line-height: 22px;
	font-weight: 400;
	display: inline-block;
}

.awesome-city-picker-field {
	&__input,
	&__value {
		color: $blueBrandColor;
	}

	&_active {
		@media only screen and (max-width: 767px) {
			background: $blueBrandColor;
		}
	}
}

.awesome-date-picker {
	&__header {
		background: $blueBrandColor;
	}
}

body .moment-picker .month-view .moment-picker-specific-views {
	@media only screen and (max-width: 767px) {
		td {
			&.highlighted {
				color: white;
				background: lighten($blueBrandColor, 20%);
			}
		}
	}
}

.passenger-calc-drop__header {
	background: $blueBrandColor;
}

.passenger-count__minus .svg-sprite-icon *,
.passenger-count__plus .svg-sprite-icon * {
	fill: $blueBrandColor;
}

.passenger-calc-drop__close-button {
	background: $blueBrandColor;
	border-color: $blueBrandColor;

	&:hover {
		background: $redBrandColor;
		border-color: $redBrandColor;
	}
}

.passenger-count__minus:not(.disabled):active,
.passenger-count__plus:not(.disabled):active {
	@media only screen and (max-width: 767px) {
		background: $blueBrandColor;
	}
}

@media (min-width: 1050px) {
	.wrap {
		max-width: 1300px !important;
	}
}

.flightTable .contentRow {
	td.active {
		.price-cell__text {
			&:before {
				border-color: #ffffff;
				content: '';
				left: -30px;
			}

			&:after {
				width: 10px;
				height: 10px;
				background: $white;
				left: -25px;
			}
		}
	}

	.price-cell__text {
		position: relative;

		&:before,
		&:after {
			display: inline-block;
			position: absolute;
			content: '';
			border-radius: 50%;
			top: 50%;
			left: -30px;
			transform: translate(0, -50%);
			box-sizing: border-box;
		}

		&:before {
			border: 2px solid #424242;
			width: 20px;
			height: 20px;
		}
	}
}

/* ==== #38002 ==== */

.orderSearchForm {
	width: 100%;
}

.header.header_small .logo {
	width: 220px;
}

.orderSearchForm .inpCol:first-child {
	width: 437px;
}

@media only screen and (max-width: 1310px) {
	.orderSearchForm .inpCol:first-child {
		width: 75%;
	}

	.orderSearchForm .inpCol:nth-child(2) {
		width: 24%;
	}

	.orderSearchForm .inpCol__i {
		width: 100%;
	}
}

.chooseResult__i {
	display: flex;
	justify-content: space-between;
}

.chooseResult__i .col:first-child {
	width: 80%;
}

/* ==== END #38002#note-209 ==== */

.out {
	min-height: calc(100% - 301px);
	//@include adaptivity(tablet) {
	//	overflow-x: visible;
	//}
}

/* ==== END 38002 ==== */

/* ==== #43418#26 ==== */

.passengers.order-item,
.webRegistration .registration__passengers_ready .route .item .passenger .passenger_lnk {
	background: rgba(73, 90, 102, 0.05);
}

.popover .popover-inner {
	background: $blueBrandColor;
}

.popover svg.svg-symbol-popover-arrow *,
.tooltip-cont .tooltip svg.svg-symbol-tooltip-arrow * {
	fill: $blueBrandColor;
}

.nav > li.empty::after {
	border: none;
}

.nav > li > div {
	z-index: 1000;
}

@media only screen and (max-width: 850px) {
	.nav.navbar-nav.hidden-xs {
		display: none;
	}
}

.regSteps ol {
	@include adaptivity(tablet) {
		display: flex;
		justify-content: center;
	}

	li {
		@include adaptivity(tablet) {
			width: 33%;
		}
	}
}

#oxyCheckinApp {
	height: auto;
	margin-bottom: 221px;
}

.regSteps ol li {
	border-bottom: none;

	&.active {
		background: $blueBrandColor;
		color: #fff;
		border-bottom: none;

		& > * {
			cursor: default;
		}

		a {
			color: #fff;

			&:hover {
				background: $blueBrandColor;
			}
		}

		&::after {
			color: #fff;
			border-color: #fff;
		}
	}
}

.regSteps ol li.prev a:hover {
	color: $blueBrandColor;
}

.webRegistration .registration__footer .btn:hover {
	border-color: $redBrandColor;
}

.popup_webRegistration .popup__i__webReg__dataPassenger {
	background: $blueBrandColor;

	&__name {
		color: #fff;
	}

	.pass-ffp,
	.pass-data-empty,
	.pass-document {
		color: #ccc;
	}
}

.tooltip-cont .tooltip .tooltip-inner {
	background: $blueBrandColor;
}

footer {
	height: 221px;
	@include adaptivity(tablet) {
		height: 281px;
	}
}

.webRegistration .btn {
	background: $blueBrandColor;
	color: #fff;

	&:hover {
		background: $redBrandColor;
	}
}

.passengerDataSeat__tabs__item.passenger-item-active {
	background: $blueBrandColor;
	color: #fff !important;
}

.modal .modal-header {
	background: $blueBrandColor;
}

.seatSelect .info ul li.selected span,
.seatSelect .seat.active,
.seatSelect .seat.seat-selected,
.seatSelect .seat.seat-order {
	background: $redBrandColor;
}

.seatSelect .info ul li.paid span {
	background: $blueBrandColor;
}

.searchSteps ol li.prev:hover,
.regSteps ol li.prev:hover {
	a {
		background: $blueBrandColor;
		color: #fff !important;
	}

	&::after {
		color: #fff;
		border-color: #fff;
	}
}

/* ==== END #43418#26 ==== */

/* ==== 44485 ==== */

.btn_next.disabled {
	&:hover {
		.infoBox {
			background: $blueBrandColor;
			opacity: 1;
			visibility: visible;
			text-align: center;

			&:after {
				border-top-color: $blueBrandColor;
			}

			@include adaptivity(tablet) {
				position: fixed;
				width: 80%;
				bottom: 20%;

				//animation: showAndHide 2s ease-in-out;
				&:after {
					display: none;
				}
			}
		}
	}
}

/* ==== END 44485 ==== */
/* ==== #43754#2 ==== */

.awesome-simple-route__row {
	@include adaptivity(tablet) {
		display: flex;
		flex-direction: column;

		.awesome-simple-route__col-date {
			&:first-child,
			&:last-child {
				padding: 5px 0;
				width: 100%;
			}
		}
	}
	&:nth-child(1) {
		@include adaptivity(laptop) {
			display: flex;
			flex-direction: row;
		}
	}
}

body .moment-picker .moment-picker-container.open {
	@include adaptivity(tablet) {
		top: 0;
		height: 100vh;
	}
}

.awesome-city-drop {
	@include adaptivity(tablet) {
		max-height: 100vh;
	}
}

/* ==== END #43754#2 ==== */

/* ==== 0-46-update-hotfix ==== */

.content__i.content__refund .btn.btn_back,
.ticketChange .btnRow .btn {
	color: $white;
	background: $blueBrandColor;

	&:hover {
		background: $redBrandColor;
	}
}

/* ==== END 0-46-update-hotfix ==== */

/* ==== 0-46-update-calendar and hotfixes ==== */
.content__i.content__refund .btn.btn_back {
	display: inline !important;
}

.btn.btn_back {
	padding: 10px 15px 10px 35px;
}

.awesome-simple-route__switch-button .svg-switch-arrows *,
.passenger-calc-field__icon .svg-drop-arrow *,
.flight-date-picker-field__icon *,
.flight-date-picker-drop__header-icon svg *,
.passenger-count__plus .svg-sprite-icon *,
.passenger-count__minus .svg-sprite-icon * {
	fill: $blueBrandColor;
}

.flight-date-picker-drop__header-text {
	color: $blueBrandColor;
}

.flight-date-picker-drop__switch {
	height: 22px;
	width: 22px;

	svg {
		margin: 5px auto;
		width: 10px;
	}

	&:hover {
		background: $blueBrandColor;
		color: $white;
		border-color: $blueBrandColor;
	}
}

.passenger-count__plus,
.passenger-count__minus {
	&:not(.disabled) {
		&:hover {
			background: $blueBrandColor;
			color: $white;

			@include adaptivity(tablet) {
				background: transparent;
			}
		}
	}
}

.awesome-simple-route,
.awesome-search-form-footer__row:not(:last-child) {
	padding-right: 180px;

	@include adaptivity(tablet) {
		padding-right: 0;
	}
}

.flight-date-picker-drop__header {
	@include adaptivity(tablet) {
		background: $blueBrandColor;

		.flight-date-picker-drop__header-text {
			color: $white;
		}
	}
}

.flight-date-picker-field {
	border: none;

	&:hover {
		border: none;
	}

	.flight-date-picker-field__placeholder {
		background: $white;
		border: #ccc 1px solid;

		.flight-date-picker-field_locked {
			height: 42px;
			background: $white;
		}
	}
}

.awesome-city-picker-field__placeholder:before {
	content: '\25bc';
	font-family: 'FontAwesome';
	font-size: 9px;
	position: absolute;
	right: 20px;
	color: $blueBrandColor;
}

.awesome-city-drop.awesome-city-drop__item {
	&:hover {
		background: $blueBrandColor 0.3;
	}
}

@include disabledCalendarStyles {
	background: white;
}

div.flight-date-picker-drop.flight-date-picker-drop_there,
div.flight-date-picker-drop.flight-date-picker-drop_back {
	width: 762px;
	top: 40px;

	@include adaptivity(tablet) {
		width: 100%;
		top: 0;
	}
}

.flight-date-picker-drop__months {
	justify-content: space-evenly;

	.flight-date-picker-mohth-table {
		width: 45%;
		margin: 0;

		@include adaptivity(tablet) {
			width: 90%;
			margin-top: 5%;
		}

		.flight-date-picker-mohth-table__day-cell {
			border: 1px solid $white;
		}
	}
}

.flight-date-picker-drop__footer-button {
	color: $blueBrandColor;
	border: 1px solid $blueBrandColor;
	margin-top: 20px;

	&:hover {
		color: $white;
		background: $blueBrandColor;
	}
}

@include enabledCalendarStyles {
	background: $blueBrandColor 0.6;
	color: $white;

	&:hover {
		background: $blueBrandColor;
	}
}

.flight-date-picker-field__close {
	display: none;
}

.flight-date-picker-mohth-table__day-cell_available {
	background: #3e8dd38c;
	position: relative;

	&:hover {
		background: $blueBrandColor;
		color: $white;

		&:before {
			content: '\25b6';
			color: $white;
			font-family: 'FontAwesome';
			position: absolute;
			left: 0;
			top: 60%;
			transform: translateY(-50%);
		}
	}
}

.flight-date-picker-mohth-table__day-cell_without-flights {
	background: white;

	&:hover {
		color: #ccc;
	}
}

.flight-date-picker-drop_back .flight-date-picker-mohth-table__day-cell_available:hover:before {
	content: '	\25C0';
	color: $white;
	font-family: 'FontAwesome';
	position: absolute;
	left: 75%;
	top: 60%;
	transform: translateY(-50%);
}

.flight-date-picker-mohth-table__day-cell_today,
.awesome-search-form-footer__mode-swither {
	color: $blueBrandColor;
}

.flight-date-picker-field__selected {
	border: #ccc 1px solid;
}

.flight-date-picker-mohth-table__day-cell_selected,
.flight-date-picker-mohth-table__day-cell_opposite {
	background: $blueBrandColor;
	color: $white;
}

.flight-date-picker-mohth-table__day-cell_interval {
	background: #03abf23d;
	color: #696969;
}

.awesome-search-form-footer__col-button .btn {
	background: $blueBrandColor;
}

.awesome-simple-route__switch-button:hover {
	background: $blueBrandColor;
	border-color: $blueBrandColor;
}

div.flight-date-picker-drop.flight-date-picker-drop_advanced {
	width: 762px;
	right: 0;

	@include adaptivity(tablet) {
		width: 100%;
	}
}

.awesome-advanced-route__remove-segment,
.awesome-advanced-route__remove-segment:hover,
.passenger-calc-drop__header,
.passenger-calc-drop__close-button,
.awesome-city-picker-field_origin.awesome-city-picker-field_active,
.awesome-city-picker-field_destination.awesome-city-picker-field_active,
.awesome-date-picker__header {
	background: $blueBrandColor;
}

.awesome-advanced-route__remove-segment svg * {
	fill: #fff;
}

.awesome-search-form-footer__mode-swither svg * {
	fill: $blueBrandColor;
}

.awesome-advanced-route {
	padding-right: 180px;
}
/* ==== END 0-46-update-calendar and hotfixes ==== */

/* ==== #46301 ==== */

search-schedule-form flight-date-picker .flight-date-picker-drop__months {
	width: 762px;

	@include adaptivity(tablet) {
		width: 100%;
	}
}

/* ==== END #46301 ==== */

/* ==== 0-48-update ==== */

.timeTable .wrap-table table .cell-date.active,
.timeTable .wrap-table table .cell-date.active:hover {
	background: $blueBrandColor;

	a {
		color: $white;
	}
}

.modal-box {
	@include adaptivity(tablet) {
		width: 100vw;
	}
}

/* ==== END 0-48-update ==== */
