@function get-deep($map, $keys...) {
	// https://css-tricks.com/snippets/sass/deep-getset-maps/
	@each $key in $keys {
		$map: map-get($map, $key);
	}
	@return $map;
}

/* ==== Миксин для стилизации awesome-search-form ==== */
/* Принимает объект со стилями для основных элементов формы поиска

	$searchFormSettings: (
		mainBgColor: white,
		inputs: (
			color: $primaryBlueColor,
			placeholderColor: $primaryBlueColor,
			selectedCityColor: $primaryBlueColor,
			selectedCountryColor: $primaryBlueColor,
			selectedCodeColor: $primaryBlueColor,

			dropdown: (
				dropdownPortColor: $primaryBlueColor,
				dropdownCityColor: $primaryBlueColor,
				dropdownCountryColor: $primaryBlueColor,
				dropdownCodeColor: $primaryBlueColor
			)
		),
		svg: (
			color: $primaryBlueColor,
		),
		swapIcon: (
			svgColor: $primaryBlueColor,
			hoverSvgColor: white,
			borderColor: $primaryBlueColor,
			bgColor: $primaryBlueColor,
			hoverBgColor: $primaryBlueColor
		),
		calendar: (
			titleColor: $primaryBlueColor,
			monthChangeButtons: (
				backgroundColor: white,
				color: $gray,
				hoverColor: white,
				backgroundHoverColor: $primaryBlueColor,
			),
			dates: (
				today: (
					color: white,
					background: #10c2dd,
				),
				selected: (
					color: white,
					background: $secondaryBlueColor,
				),
				normal: (
					color: black,
					background: transparent,
				),
				disabled: (
					background: transparent,
					color: #d6d6d6
				),
				hover: (
					color: white,
					background: $primaryBlueColor,
				)
			),
			bottomButton: (
				color: $primaryBlueColor,
				border-color: $primaryBlueColor,
				hover: (
					color: white,
					borderColor: $secondaryBlueColor,
					backgroundColor: $secondaryBlueColor
				),
			)
		),
		searchButton: (
			color: white,
			backgroundColor: $primaryBlueColor,
			hover: (
				color: white,
				backgroundColor: $thirdBlueColor,
			)
		),
		passengers: (
			color: $primaryBlueColor,
			ageColor: $gray,
		)
	);
 */

@mixin awesomeSearchForm($settingsObject) {
	.awesome-city-picker-field__placeholder,
	.awesome-city-drop__placeholder-text,
	.awesome-date-picker__placeholder,
	.awesome-date-picker__locker,
	.passenger-calc-field__count,
	.bonus-control__intro,
	.bonus-control__field .textInp {
		color: get-deep($settingsObject, inputs, placeholderColor);

		@include placeholder {
			color: get-deep($settingsObject, inputs, placeholderColor);
		}
	}
	.awesome-city-picker-field {
		$inputs: get-deep($settingsObject, inputs);

		&__selected-city {
			color: get-deep($inputs, selectedCityColor);
		}

		&__selected-country {
			color: get-deep($inputs, selectedCountryColor);
		}

		&__selected-code {
			color: get-deep($inputs, selectedCodeColor);
		}
	}

	.awesome-city-drop {
		$dropdown: get-deep($settingsObject, inputs, dropdown);

		&__port {
			color: get-deep($dropdown, dropdownPortColor);
		}

		&__city {
			color: get-deep($dropdown, dropdownCityColor);
		}

		&__country {
			color: get-deep($dropdown, dropdownCountryColor);
		}

		&__code {
			color: get-deep($dropdown, dropdownCodeColor);
		}
	}

	.awesome-date-picker {
		&__value {
			&-date,
			&-day {
				color: get-deep($settingsObject, inputs, color);
			}

			color: get-deep($settingsObject, inputs, color);
		}
	}

	.awesome-city-picker-field__selected-city,
	.awesome-city-picker-field__selected-port,
	.awesome-city-drop__item_hover .awesome-city-drop__port,
	.awesome-city-drop__item_hover .awesome-city-drop__city {
		color: get-deep($settingsObject, inputs, dropdown, dropdownPortColor);
	}

	.passenger-calc-field__icon .svg-drop-arrow *,
	.bonus-control__intro .svg-sprite-icon *,
	.awesome-date-picker__icon *,
	.bonus-control__field-close .svg-sprite-icon * {
		fill: get-deep($settingsObject, svg, color);
	}

	.awesome-search-form-container {
		background: get-deep($settingsObject, mainBgColor);
	}

	.awesome-simple-route__switch-button {
		border-color: get-deep($settingsObject, swapIcon, borderColor);

		.svg-switch-arrows * {
			fill: get-deep($settingsObject, swapIcon, svgColor);
		}

		&:hover {
			background: get-deep($settingsObject, swapIcon, hoverBgColor);
			border-color: get-deep($settingsObject, swapIcon, hoverBgColor);

			.svg-switch-arrows * {
				fill: get-deep($settingsObject, swapIcon, hoverSvgColor);
				stroke: get-deep($settingsObject, swapIcon, hoverSvgColor);
			}
		}
	}

	.flight-date-picker {
		$calendar: get-deep($settingsObject, calendar);
		$monthChangeButtons: get-deep($calendar, monthChangeButtons);

		&-drop {
			&__header {
				&-text {
					color: get-deep($calendar, titleColor);
				}
				&-icon svg * {
					fill: get-deep($calendar, titleColor);
				}
			}

			&__switch {
				border-color: get-deep($monthChangeButtons, color);
				background: get-deep($monthChangeButtons, backgroundColor);

				svg * {
					fill: get-deep($monthChangeButtons, color);
				}

				&:hover {
					background: get-deep($monthChangeButtons, backgroundHoverColor);
					border-color: get-deep($monthChangeButtons, backgroundHoverColor);

					svg * {
						fill: get-deep($monthChangeButtons, hoverColor);
					}
				}
			}

			&__footer-button {
				$bottomButton: get-deep($settingsObject, calendar, bottomButton);
				color: get-deep($bottomButton, color);
				border: 1px solid get-deep($bottomButton, borderColor);

				&:hover {
					color: get-deep($bottomButton, hover, color);
					border: 1px solid get-deep($bottomButton, hover, borderColor);
					background: get-deep($bottomButton, hover, backgroundColor);
				}
			}
		}

		&-mohth-table__day-cell {
			$dates: get-deep($calendar, dates);

			&:not(&_disabled) {
				&:hover {
					color: get-deep($dates, hover, color);
					background: get-deep($dates, hover, background);
					cursor: pointer;
				}
			}

			&_today {
				color: get-deep($dates, today, color);
				background: get-deep($dates, today, background);
			}

			&_disabled {
				color: get-deep($dates, disabled, color);
				background: get-deep($dates, disabled, background);
			}

			&_today {
				color: get-deep($dates, today, color);
				background: get-deep($dates, today, background);
			}

			&_opposite,
			&_selected {
				color: get-deep($dates, selected, color);
				background: get-deep($dates, selected, background);
			}
		}
	}

	body .moment-picker {
		$calendar: get-deep($settingsObject, calendar);
		$monthChangeButtons: get-deep($calendar, monthChangeButtons);
		$dates: get-deep($calendar, dates);

		.moment-picker-container .header-view {
			color: get-deep($calendar, titleColor);
		}

		.datepicker-arrow {
			border-color: get-deep($monthChangeButtons, color);
			background: get-deep($monthChangeButtons, backgroundColor);

			svg * {
				fill: get-deep($monthChangeButtons, color);
			}

			&:hover {
				background: get-deep($monthChangeButtons, backgroundHoverColor);
				border-color: get-deep($monthChangeButtons, backgroundHoverColor);

				svg * {
					fill: get-deep($monthChangeButtons, hoverColor);
				}
			}
		}

		.month-view {
			.moment-picker-specific-views td {
				&:not(.disabled) {
					&:hover {
						color: get-deep($dates, hover, color);
						background: get-deep($dates, hover, background);
						cursor: pointer;
					}
				}

				&.disabled {
					color: get-deep($dates, disabled, color);
					background: get-deep($dates, disabled, background);
				}

				&.today {
					color: get-deep($dates, today, color);
					background: get-deep($dates, today, background);
				}

				&.selected {
					color: get-deep($dates, selected, color);
					background: get-deep($dates, selected, background);
				}
			}
		}
	}

	.awesome-date-picker {
		$bottomButton: get-deep($settingsObject, calendar, bottomButton);

		&__oneway-only-button {
			color: get-deep($bottomButton, color);
			border-color: get-deep($bottomButton, borderColor);

			&:hover {
				color: get-deep($bottomButton, hover, color);
				border-color: get-deep($bottomButton, hover, borderColor);
				background: get-deep($bottomButton, hover, backgroundColor);
			}
		}
	}

	.awesome-search-form-footer {
		$button: get-deep($settingsObject, searchButton);

		&__col-button {
			.btn {
				color: get-deep($button, color);
				background-color: get-deep($button, backgroundColor);

				&:hover {
					color: get-deep($button, hover, color);
					background-color: get-deep($button, hover, backgroundColor);
				}
			}
		}
	}
	.passenger-calc-line {
		$passengers: get-deep($settingsObject, passengers);

		&__title {
			color: get-deep($passengers, color);
		}

		&__age {
			color: get-deep($passengers, ageColor);
		}
	}
}

/* ==== END Миксин для стилизации awesome-search-form ==== */
